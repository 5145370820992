import { ExpressCheckoutElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { useLocation } from '@reach/router';
import tw from 'twin.macro'
import { useState } from "react";

const CheckoutExpress = ({ setIsLoading, orderInfo, createOrderPayload, createAccPayload, shipping, show }) => {
    const elements = useElements();
    const stripe = useStripe();
    const location = useLocation();
    const [hasPaymentMethods, setHasPaymentMethods] = useState(false);
    const expressCheckoutOptions = {
        wallets: {
            applePay: 'always',
            googlePay: 'always'
        }
    }

    const createPaymentIntent = async () => {
        try {

            const orderPayloads = {
                orderData: { ...createOrderPayload(shipping, shipping) },
                salesforceData: { ...createAccPayload(shipping, shipping) }
            }
    
            const res = await axios.post('/.netlify/functions/graphql',
                {
                    query: `
            mutation createPaymentIntent(
                $product: [OrderItemInput]
                $amount: Int
                $currency: String
                $email: String
                $name: String
                $orderID: String
            ) {
                createPaymentIntent(
                    product: $product
                    amount: $amount
                    currency: $currency
                    email: $email
                    name: $name
                    orderID: $orderID
                ) {
                    clientSecret
                }
            }`,
                    variables: {
                        product: orderInfo.product,
                        amount: orderInfo.amount,
                        currency: orderInfo.currency,
                        email: shipping.email,
                        name: `${shipping.firstName} ${shipping.lastName}`,
                        orderID: orderPayloads.orderData._id
                    },
                },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            const clientSecret = res.data.data.createPaymentIntent.clientSecret;
            const storageKey = clientSecret.slice(-5)
            if (orderPayloads.orderData.payment?.stripeSourceId) {
                delete orderPayloads.orderData.payment.stripeSourceId;
            }
            localStorage.setItem(`ongoing_order_${storageKey}`, JSON.stringify(orderPayloads))
            return clientSecret;
        } catch (error) {
            throw error;
        }
    }

    const onConfirm = async (_event) => {
        try {
            setIsLoading(true);
            const { error: submitError } = await elements.submit();
            if (submitError) {
                setErrorMessage(submitError.message);
                return;
            }

            // Create the PaymentIntent and obtain clientSecret
            const clientSecret = await createPaymentIntent();

            // Confirm the PaymentIntent using the details collected by the Express Checkout Element
            const { error } = await stripe.confirmPayment({
                // `elements` instance used to create the Express Checkout Element
                elements,
                // `clientSecret` from the created PaymentIntent
                clientSecret,
                confirmParams: {
                    return_url: `${location.origin}/shop/checkout/verify`,
                },
            });

            if (error) {
                setIsLoading(false);
                alert(error.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log('ERR:', error.message);
        }
    }

    const onReady = (event) => {
        if (event.availablePaymentMethods) {
            setHasPaymentMethods(true);
        } else {
            setHasPaymentMethods(false);
        }
    }

    return (
        <div css={[tw`w-full`, !show && tw`hidden`]}>
            {hasPaymentMethods && 
                <div css={tw`my-4 w-full text-center`}>
                    <span>or</span>
                </div> 
            }
            
            <ExpressCheckoutElement onReady={onReady} onConfirm={onConfirm} options={expressCheckoutOptions} />
        </div>
    )
}

export default CheckoutExpress;